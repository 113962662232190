import { useCallback } from 'react'
import { atom, useRecoilValue, useSetRecoilState } from 'recoil'

type ThemeState = {
  theme: 'light' | 'dark'
}

const themeRecoilState = atom<ThemeState>({
  key: 'themeRecoilState',
  default: { theme: 'dark' },
})

export const useThemeState = () => {
  return useRecoilValue(themeRecoilState)
}

export const useThemeMutators = () => {
  const setState = useSetRecoilState(themeRecoilState)

  const loadTheme = useCallback(() => {
    const theme = localStorage.getItem('theme')
    if (theme === 'dark' || theme === 'light') {
      setState({ theme })
    }
  }, [])

  const setTheme = useCallback(
    (theme: ThemeState['theme']) => {
      if (window) {
        window.localStorage.setItem('theme', theme)
      }
      setState({ theme })
    },
    [setState],
  )

  return { loadTheme, setTheme }
}
