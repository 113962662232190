import React, { useEffect } from 'react'
import type { WrapRootElementBrowserArgs, WrapPageElementBrowserArgs } from 'gatsby'
import { RecoilRoot } from 'recoil'

import './src/styles/destyle.css'
import './src/styles/global.scss'

import { useThemeState, useThemeMutators } from './src/hooks/useTheme'

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs) => {
  return <RecoilRoot>{element}</RecoilRoot>
}

export const wrapPageElement = ({ element }: WrapPageElementBrowserArgs) => {
  const { loadTheme } = useThemeMutators()
  const themeState = useThemeState()

  useEffect(() => {
    loadTheme()
  }, [])

  useEffect(() => {
    document.body.className = themeState.theme === 'dark' ? 'dark' : 'light'
  }, [themeState.theme])

  return <>{element}</>
}
